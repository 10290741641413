import React, { useContext, useEffect, useState } from "react";
import Title from "../../components/Title";
import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Label,
  List,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import AppContext from "../../AppContext";
import "./style.scss";
import { normalizeBoolJSX } from "../../utils/functions";

export interface IItem {
  Error?: String;
  Semana: number;
  Anyo: number;
  Visible: boolean;
  Ventas: string;
  Eventos: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    width: 80,
    transform: "translateY(4px)",
    marginBottom: 8,
    marginLeft: 14,
    zIndex: 1,
    display: "flex",
    gap: 1,
  },
  label: {
    color: "var(--primary-color)",
    fontWeight: 400,
    padding: "7px 0px",
    fontSize: "13px",
    margin: "0px 6px 0px 0px",
  },
  title: {
    borderColor: "transparent",
    transition: "background 0.3s",
  },
  dropdown: {
    selectors: {
      "&:hover .ms-Dropdown-title, &:active .ms-Dropdown-title": {
        borderColor: "transparent",
        background: "#f3f2f1",
      },
      ":focus::after": {
        border: "1px solid transparent",
      },
    },
  },
  callout: {
    minWidth: 130,
  },
};

const CuadroVisibilidad = () => {
  const { semanasDatos } = useContext(AppContext);
  const [selectedYear, setSelectedYear] = useState<IDropdownOption | null>({
    key: semanasDatos[1].year,
    text: `${semanasDatos[1].year}`,
  });
  const [items, setItems] = useState<any[] | undefined>();
  //const [loading, setLoading] = useState(false)

  const [setRow] = useMutation(gql`
    mutation ($year: Int!, $week: Int!, $visible: Boolean) {
      setWeekVisibility(
        input: { Year: $year, Week: $week, Visible: $visible }
      ) {
        Completed
        Error
      }
    }
  `);

  const WEEK_QUERY = gql`
    query ($year: Int!) {
      getWeekVisibility(Anyo: $year)
    }
  `;

  let qry = useQuery(WEEK_QUERY, {
    variables: {
      year: selectedYear?.key,
    },
  });

  useEffect(() => {
    if (qry.data?.getWeekVisibility === undefined) return;
    let newData: any = qry.data?.getWeekVisibility?.filter(
      (i: IItem) => i.Semana !== 1
    );
    let newData2 = newData.map((i: IItem) => {
      return {
        ...i,
        Semana: i.Semana - 1,
      };
    });
    setItems(newData2);
  }, [qry.data]);

  function _onChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {
    if (!(ev?.target instanceof HTMLInputElement)) return;
    let vars = {
      year: parseInt(`${ev?.target.dataset?.year}`),
      week: parseInt(`${ev?.target.dataset?.week}`),
      visible: isChecked,
    };
    setRow({
      variables: {
        year: parseInt(`${ev?.target.dataset?.year}`),
        week: parseInt(`${ev?.target.dataset?.week}`) + 1, // +1 para que apunte a la semana REAL en BBDD // FIX ERA
        visible: isChecked,
      },
    }).then(() => {
      qry.refetch();
    });
  }

  const onRenderCell = (
    item?: IItem,
    index?: number | undefined
  ): JSX.Element => {
    let vals: number[] = item?.Ventas.split("/").map((x) => +x) || [0, 0];
    let cellClass = "",
      tooltip = "";
    //if(vals[0] === vals[1]) cellClass = "completed"
    if (vals[0] > vals[1]) {
      cellClass = "overflow";
      tooltip = "Hay datos de más";
    }
    if (vals[0] < vals[1]) {
      cellClass = "incomplete";
      tooltip = "Datos de ventas incompletos";
    }
    if (vals[0] * 2 < vals[1]) {
      cellClass = "empty";
      tooltip = "Datos de ventas casi vacíos";
    }

    return !item ? (
      <></>
    ) : (
      <div key={index} className="row">
        <div
          className="cell value small"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Checkbox
            className={"visibilityCheckbox"}
            checked={item.Visible}
            onChange={_onChange}
            inputProps={{
              ...({
                "data-week": `${item.Semana}`,
                "data-year": `${item.Anyo}`,
              } as any),
            }}
          />
        </div>
        <div className="cell value">
          {item.Semana < 10 ? `0${item.Semana}` : `${item.Semana}`}/{item.Anyo}
        </div>
        <div className={`cell value ${cellClass}`}>
          {cellClass === "" ? (
            <>{item.Ventas}</>
          ) : (
            <abbr title={tooltip}>{item.Ventas}</abbr>
          )}
        </div>
        <div className="cell value">{normalizeBoolJSX(item.Eventos)}</div>
      </div>
    );
  };

  let yearOpts: IDropdownOption[] = [];
  /*
  useEffect(() => {
    for (
      let year = semanasDatos[0].year;
      year <= semanasDatos[1].year;
      year++
    ) {
      yearOpts.push({ key: year, text: `${year}` });
    }
  });
	*/

  yearOpts = [
    { key: 2019, text: "2019" },
    { key: 2020, text: "2020" },
    { key: 2021, text: "2021" },
    { key: 2022, text: "2022" },
    { key: 2023, text: "2023" },
    { key: 2024, text: "2024" },
  ];

  const changeYear = (_: any, item: any) => {
    setSelectedYear(item);
  };

  const itemsCount: number = items ? items?.length : 0;
  const itemsActive: number = items
    ? items.filter((i) => !!i.Visible).length
    : 0;

  return (
    <>
      <Title title={"Cuadro de visibilidad"} />

      <div className={`table-container small`}>
        <div className="table">
          <Stack horizontal>
            <Dropdown
              label="Año"
              placeholder={selectedYear ? selectedYear.text : ""}
              options={yearOpts}
              styles={dropdownStyles}
              onChange={changeYear}
            />
            <Label
              style={{
                margin: "auto 12px auto auto",
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {itemsCount === 0
                ? ""
                : `${itemsActive}/${itemsCount} semanas visibles`}
            </Label>
          </Stack>
          {qry?.loading && (
            <Spinner
              style={{ paddingBottom: "20px" }}
              size={SpinnerSize.large}
            />
          )}
          {!qry?.loading && (
            <>
              <div className="row header">
                <div className="cell headercell small"></div>
                <div className="cell headercell">Semana</div>
                <div className="cell headercell">Ventas</div>
                <div className="cell headercell">Eventos</div>
              </div>

              <List items={items} onRenderCell={onRenderCell} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CuadroVisibilidad;

import React, { useContext, useEffect, useState } from "react";
import Title from "../../../components/Title";
import MiniTitle from "../../../components/MiniTitle";
import { ComparadorTendencia } from "../Tendencia";
import { ComparadorCuotaHorizontal } from "../CuotaHorizontal";
import { ComparadorGap } from "../Gap";
import AppContext, { IFilters } from "../../../AppContext";

export const title = `Análisis Comparativo`;
export const filters: IFilters = {
  limitedPeriodo: true,
  showMedidas: true,
  hasTicketMedio: true,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: true,
  showMyData: true,
  mustSelectChain: true,
  showVariable: true,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  const [variables, setVariables] = useState({
    yr1: semanas[0].year,
    wk1: semanas[0].week,
    yr2: semanas[1].year,
    wk2: semanas[1].week,
    var: 1,
    segment: filters.segmentos[filters.variables],
    misdatos: filters.misdatos,
  });

  useEffect(() => {
    setVariables((variables) => ({
      ...variables,
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      //var: 1,
      // segment: filters.segmentos[filters.variables],
      // misdatos: filters.misdatos,
    }));
  }, [semanas, user]);

  const [graphData, setGraphData] = useState<any>(null);

  const [selectedGraph, setSelectedGraph] = React.useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>(
    `Se ha producido un error recuperando los datos`
  );
  const [graphOrder, setGraphOrder] = React.useState<any>({
    g0: 0,
    g1: 1,
    g2: 2,
  });

  const graphTitles: any = {
    0: "Velocidad (Tendencia)",
    1: "Relevancia (Peso relativo)",
    2: "Diferencial (Gap)",
  };

  const [legend, setLegend] = React.useState<any>([]);

  /*
      exportData = getExportData(
        data,
        datalabels,
        labels,
        semanas[0].year === 2019
      );*/

  //let exportData: any[] = [];
  const [exportData, setExportData] = useState<any[]>([]);

  const handleGraphClick = (graph: number, miniGraphId: any) => {
    let newGraph = 0;
    let temp0 = graphOrder.g0;

    if (graph === 1) {
      newGraph = graphOrder.g1;
      setGraphOrder({
        ...graphOrder,
        g0: newGraph,
        g1: temp0,
      });
    } else {
      newGraph = graphOrder.g2;
      setGraphOrder({
        ...graphOrder,
        g0: newGraph,
        g2: temp0,
      });
    }
  };

  const handleLegend = (legend: any) => {
    // console.log("legend: ", legend);
    setLegend(legend);
  };

  return (
    <div
      className="graph"
      style={{
        position: "relative",
        minHeight: "450px",
        width: "100%",
      }}
    >
      <Title
        title={`${graphTitles[graphOrder.g0]}`}
        error={false}
        files={{
          data: exportData,
          semanas,
          user,
          title: `${graphTitles[graphOrder.g0]}`,
          leyenda: "Periodo",
        }}
      />
      {/*<MessageBar isMultiline={true}>{errorMsg}</MessageBar>*/}
      <div className="graph-container">
        {graphOrder.g0 === 0 && (
          <ComparadorTendencia
            data={graphData}
            legend={legend}
            hue={320}
            annotation={"#00ff00"}
            percentages
            variables={variables}
            filters={filters}
            updateLegend={handleLegend}
            updateExportData={(data: any) => {
              setExportData(data);
            }}
          />
        )}
        {graphOrder.g0 === 1 && (
          <ComparadorCuotaHorizontal
            data={graphData}
            legend={legend}
            hue={320}
            annotation={"#00ff00"}
            percentages
            variables={variables}
            filters={filters}
            updateLegend={handleLegend}
            isMaximized
            showLegend
            updateExportData={(data: any) => {
              setExportData(data);
            }}
          />
        )}
        {graphOrder.g0 === 2 && (
          <ComparadorGap
            data={graphData}
            legend={legend}
            hue={320}
            annotation={"#00ff00"}
            percentages
            variables={variables}
            filters={filters}
            updateLegend={handleLegend}
            updateExportData={(data: any) => {
              setExportData(data);
            }}
          />
        )}
      </div>
      <div className="graph-selector">
        <div className="test-div">
          <div className="mini-graph">
            <MiniTitle
              title={`${graphTitles[graphOrder.g1]}`}
              error={false}
              files={{
                data: exportData,
                semanas,
                user,
                title,
                leyenda: "Periodo",
              }}
              onClick={() => {
                handleGraphClick(1, graphOrder.g1);
              }}
            />
            {graphOrder.g1 === 0 && (
              <ComparadorTendencia
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
            {graphOrder.g1 === 1 && (
              <ComparadorCuotaHorizontal
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
            {graphOrder.g1 === 2 && (
              <ComparadorGap
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
          </div>
          <div className="mini-graph">
            <MiniTitle
              title={`${graphTitles[graphOrder.g2]}`}
              error={false}
              files={{
                data: exportData,
                semanas,
                user,
                title,
                leyenda: "Periodo",
              }}
              onClick={() => {
                handleGraphClick(2, graphOrder.g2);
              }}
            />
            {graphOrder.g2 === 0 && (
              <ComparadorTendencia
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
            {graphOrder.g2 === 1 && (
              <ComparadorCuotaHorizontal
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
            {graphOrder.g2 === 2 && (
              <ComparadorGap
                data={graphData}
                legend={legend}
                hue={320}
                annotation={"#00ff00"}
                percentages
                variables={variables}
                filters={filters}
                updateLegend={handleLegend}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;

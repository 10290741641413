import { useState, useEffect, useRef, useContext } from "react";
//import Line from "react-apexcharts";
//import { ResponsiveLine } from "@nivo/line";

import { Chart } from "react-chartjs-2";
import { Chart as Chart2 } from "chart.js";
import { gql, useLazyQuery } from "@apollo/client";
import { Entorno } from "../../../utils/constants";
import { getItemHexColor, mergeKeyData } from "../../../utils/functions";
import Chainz from "../../../dictionaries/Chainz.json";
import { colProperties, Spinner, SpinnerSize } from "@fluentui/react";
import { GraphNotCompatible } from "../../../components/GraphNotCompatible";
import AppContext from "../../../AppContext";

import { getMedidaSQL } from "../../../utils/functions";

import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import ChartDataLabels from "chartjs-plugin-datalabels";

import "./style.scss";

Chart2.register(ChartjsPluginStacked100);
Chart2.register(ChartDataLabels);

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  hue: number;
  annotation: string;
  percentages?: boolean;
};

export const ComparadorCuotaHorizontal = ({
  variables,
  hue,
  annotation,
  percentages,
  legend,
  updateLegend,
  isMaximized,
  updateExportData,
  showLegend,
}: any) => {
  let statusRef = useRef<any>([]);
  const graphRef = useRef<any>(null);
  let appContext = useContext(AppContext);
  const { semanas, filters, user } = useContext(AppContext);

  const [normalize, setNormalize] = useState(true);

  let currentFilters = useRef<any>(null);

  const medida = getMedidaSQL(filters.medidas);
  let entorno =
    filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

  let leverTypeKeys: any = [
    "Totales",
    "Canal",
    "Momento",
    "Region",
    "Ubicacion",
  ];
  let leverKeys: any = {
    Canal: { C0: "Total", C1: "Local", C2: "TakeAway", C3: "Delivery" },
    Momento: {
      M0: "Total",
      M1: "Mañanas",
      M2: "Tardes",
      M3: "Comidas (L-J)",
      M4: "Cenas (L-J)",
      M5: "Comidas (V-D)",
      M6: "Cenas (V-D)",
    },
    Region: {
      Z0: "Total",
      Z1: "Madrid",
      Z2: "Barcelona",
      Z3: "Norte",
      Z4: "Centro",
      Z5: "Noreste",
      Z6: "Sur",
    },
    Ubicacion: { U0: "Total", U1: "Calle", U3: "CC", U4: "Resto" },
  };

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
      $misdatos: Int
      $leverType: String
      $lever: String
    ) {
      graph(
        Graph: "Mi cuota comparador"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
          misdatos: $misdatos
          LeverType: $leverType
          Lever: $lever
        }
      )
    }
  `;

  const [graphData, setGraphData] = useState<any>(null);
  const [errorMsg, setErrorMsg] = useState<string>(
    `Se ha producido un error recuperando los datos`
  );
  const [isCompatible, setCompatible] = useState<boolean>(true);

  const [fetchData, { loading, error, data }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      !currentFilters?.current ||
      (currentFilters?.current &&
        (filters.segmentType !== currentFilters?.current.segmentType ||
          filters.segment !== currentFilters?.current.segment))
    ) {
      let leverTypeStr = "";
      let leverStr = "";
      fetchData({
        variables: {
          yr1: semanas[0].year,
          wk1: semanas[0].week,
          yr2: semanas[1].year,
          wk2: semanas[1].week,
          var: filters?.variables || 0,
          segment:
            filters.segmentType === 0
              ? filters?.segmentos[filters?.segment]
              : filters?.especialidades[filters?.segment],
          segmentType: filters.segmentType,
          misdatos: 0,
          leverType: leverTypeStr || "",
          lever: leverStr || "",
        },
      });
    }
    currentFilters.current = { ...filters };
  }, []);

  useEffect(() => {
    let leverTypeStr = "";
    let leverStr = "";

    fetchData({
      variables: {
        yr1: semanas[0].year,
        wk1: semanas[0].week,
        yr2: semanas[1].year,
        wk2: semanas[1].week,
        var: filters?.variables,
        segment:
          filters.segmentType === 0
            ? filters?.segmentos[filters?.segment] || filters?.segmentos[1]
            : filters?.especialidades[filters?.segment] ||
              filters?.especialidades[1],
        segmentType: filters.segmentType,
        misdatos: filters?.misdatos,
        leverType: leverTypeStr || "",
        lever: leverStr || "",
      },
    });
  }, [semanas, filters.segmentType, filters.segment, filters.misdatos]);

  useEffect(() => {
    if (data?.graph) {
      const medida = getMedidaSQL(filters.medidas);
      if (data?.graph?.Error || medida === "TicketMedio") {
        setCompatible(false);
        setSecondError(
          "Esta gráfica no es compatible con la medida seleccionada."
        );
        return;
      }
      if (Object.values(data?.graph[0]).length <= 1) {
        setSecondError(
          "La cadena seleccionada no está representada en el segmento seleccionado."
        );
      } else {
        setSecondError(null);
      }

      setCompatible(true);
      let entorno =
        filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

      let fetchedData: any = data?.graph;

      if (!fetchedData) throw new Error(errorMsg);
      if (fetchedData?.Error) throw new Error(`${data.Error}`);

      let labels = fetchedData?.map((d: any) => Object.keys(d)[0]);
      if (labels.length < 3)
        throw new Error(
          `La cadena no está representada en el segmento seleccionado.`
        );

      let datalabels = fetchedData[0][labels[0]].map(
        (d: { Descripcion: string }) => d.Descripcion
      );

      let dataName = fetchedData[0][labels[0]].map(
        (d: { Name: string }) => d.Name
      );

      let values: any = [];
      let series: any = [];
      let values2: any = {};

      // Filter Segment / Full market
      let segmentsArray: any = [];

      if (filters.variables === 0) {
        //
        segmentsArray = datalabels.filter(
          (item: string) => item?.indexOf("sin m") > -1
        );
      } else {
        segmentsArray = datalabels.filter(
          (item: string) =>
            item?.substring(0, 4) ===
            filters.segmentos[filters.variables].substring(0, 4)
        );
      }

      // Filter my data
      let myDataArray: any = [];

      let cadenas = datalabels.filter(
        (item: string) => item?.substring(0, 4) === "cad_"
      );

      myDataArray = [...cadenas];

      let filteredLabels: any = [];
      segmentsArray.map((item: any) => filteredLabels.push(item));
      myDataArray.map((item: any) => filteredLabels.push(item));

      values2 = filteredLabels.map((item: string) => {
        return { [item]: [] };
      });

      try {
        for (let i = 0; i < filteredLabels.length; i++) {
          let valueSub: any = [];

          fetchedData.map((dat: any): void => {
            let tempArray: any = [...Object.values(dat)];
            let index = tempArray[0].findIndex((item2: any) => {
              return (
                item2["Descripcion"].substring(0, 7) ===
                filteredLabels[i].substring(0, 7)
              );
            });

            let arrayOfItems = tempArray[0][index];
            let valueSub2 = arrayOfItems.Item[medida][entorno];
            //console.log("arrayOfItems: ", arrayOfItems);
            //console.log(valueSub2);
            // find index of the item in values2 with key matching filteredLabels[i]
            let index2 = values2.findIndex((item2: any) => {
              return (
                Object.keys(item2)[0].substring(0, 7) ===
                filteredLabels[i].substring(0, 7)
              );
            });
            //console.log("index2: ", index2);
            values2[index2][filteredLabels[i]].push(valueSub2);

            //values2[filteredLabels[i]].push(valueSub2);

            if (filters.leverType === 0) {
              valueSub.push(arrayOfItems.Item[medida][entorno].Totales);
            } else {
              let leverKey = leverTypeKeys[filters.leverType || 0];
              let leverSubKey = leverKeys[leverKey][filters.lever || 0];

              valueSub.push(
                arrayOfItems.Item[medida][entorno][leverKey][leverSubKey]
              );
            }
          });
          values.push(valueSub.flat().reverse());
        }
      } catch {
        setCompatible(false);
      }

      labels.reverse();

      values.forEach((val: any, i: any) => {
        // Find index in Chainz with key matching dataName[i]
        const index = Object.keys(Chainz).findIndex(
          (item: any) => item === dataName[i]
        );
        let color: any = "#ffffff";
        if (index !== -1) {
          //@ts-ignore
          color = `${Chainz[dataName[i]].color}`;
        }

        series.push({
          name: filteredLabels[i],
          color: color,
          data: val,
        });
      });

      const graphData = {
        datalabels: filteredLabels,
        labels: labels,
        values: values,
        series: series,
        values2: values2,
      };

      if (updateExportData) {
        currentFilters.current = { ...filters };
        let exportData = getExportData(
          fetchedData,
          filteredLabels,
          labels,
          semanas[0].year === 2019,
          currentFilters.current,
          leverTypeKeys,
          leverKeys
        );
        updateExportData(exportData);
      }
      setGraphData(graphData);
    }
  }, [data, filters, errorMsg]);

  useEffect(() => {
    //@ts-ignore -- Limitación de apexcharts?
    if (graphRef.current && graphRef.current.props.series.length > 5) {
      data.series.forEach(
        (serie: { name: string; data: number[] }, i: number) => {
          if (i > 5) graphRef?.current?.chart.hideSeries(serie.name);
        }
      );
    }
  }, [data]);

  const newLegendClickHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
    ci.update();

    // UpdateStatus
    let status = statusRef.current;
    if (status) {
      status[index].hidden = !status[index].hidden;
      //updateLegend(status);
    }
  };

  const newLegendHoverHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    setHoveredIndex(index);
    ci.update();
  };

  const newLegendLeaveHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const ci = legend.chart;
    setHoveredIndex(-1);
    ci.update();
  };

  let options = {
    responsive: true,
    tension: 0.4,
    animation: {
      duration: 400,
    },
    plugins: {
      datalabels: {
        color: showLegend ? "white" : "transparent",
        weight: "bold",
        formatter: function (value: any, context: any) {
          const data = context.chart.data;
          const datasetIndex = context.datasetIndex;
          const index = context.dataIndex;
          // const datasetLabel = data.datasets[datasetIndex].label || "";
          // You can use two type values.
          // `data.originalData` is raw values,
          // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
          //const originalValue = data.originalData[datasetIndex][index];
          // const rateValue = data.calculatedData[datasetIndex][index];
          try {
            if (filters.lever > 0) {
              if (data?.originalData) {
                return `${data.originalData[filters.lever - 1][index]?.toFixed(
                  1
                )}%`;
              }
              return `${data.datasets[0].data[index]?.toFixed(1)}%`;
            } else if (filters.leverType > 0) {
              if (data?.originalData) {
                return `${data.originalData[datasetIndex][index].toFixed(1)}%`;
              }
              return `${data.datasets[0].data[index]?.toFixed(1)}%`;
            } else {
              //return `${data.originalData[datasetIndex][index].toFixed(1)}%`;
              return "";
            }
          } catch {
            return `${data.datasets[0].data[index]?.toFixed(1)}%`;
          }
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const data = tooltipItem.chart.data;
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.dataIndex;
            const datasetLabel = data.datasets[datasetIndex].label || "";
            // You can use two type values.
            // `data.originalData` is raw values,
            // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
            //const originalValue = data.originalData[datasetIndex][index];
            //const rateValue = data.calculatedData[datasetIndex][index];
            if (filters.lever > 0) {
              if (data.originalData) {
                return `${datasetLabel}: ${data.originalData[filters.lever - 1][
                  index
                ].toFixed(1)}%`;
              }
              return `${data.datasets[0].data[index]?.toFixed(1)}%`;
            } else {
              if (data.originalData) {
                return `${datasetLabel}: ${data.originalData[datasetIndex][
                  index
                ].toFixed(1)}%`;
              } else {
                return `${data.datasets[0].data[index]?.toFixed(1)}%`;
              }
            }
            /*
            return `${datasetLabel}: Abs: ${originalValue.toFixed(
              2
            )}% Rel:${rateValue.toFixed(2)}%`;
						*/
          },
        },
      },
      stacked100: {
        enable: filters.lever === 0 ? true : false,
        replaceTooltipLabel: filters.lever === 0 ? true : false,
      },
      legend: {
        reverse: false,
        position: "bottom" as const,
        onClick: newLegendClickHandler,
        onHover: newLegendHoverHandler,
        onLeave: newLegendLeaveHandler,
        labels: {
          color: "rgb(255, 255, 255, 0.9)",
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Mi tendencia",
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        /* max: 100,*/
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: true,
          tickColor: "#26292A",
          color: "#26292A",
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(0) + "%"; // convert it to percentage
          },
        },
      },
    },
  };

  if (filters.lever === 0) {
    options = {
      ...options,
      scales: {
        ...options.scales,
        y: {
          ...options.scales.y,
          /*@ts-ignore*/
          max: 100,
        },
      },
    };
  }

  /*      yAxes: {
        max: 100,
      },
			*/

  let emptyData = {
    labels: [],
    datasets: [],
  };

  let [dataMercado, setDataMercado] = useState<any>(emptyData);
  let [dataYo, setDataYo] = useState<any>(emptyData);
  let [dataTest, setDataTest] = useState<any>(emptyData);
  let [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  let [secondError, setSecondError] = useState<any>(null);

  useEffect(() => {
    if (
      !graphData ||
      graphData?.labels?.length === 0 ||
      graphData?.series?.length === 0
    )
      return;

    //console.log("-----------");
    //console.log("graphData values2: ", graphData.values2);
    setDataMercado(emptyData);
    setDataYo(emptyData);

    /* DEVELOPMENT */
    let graphDataGrouped: any = [];
    let datasetLabels: any = [];
    //       let labels = fetchedData?.map((d: any) => Object.keys(d)[0]);
    graphData.values2.map((item: any) => {
      let keys = Object.keys(item);
      graphDataGrouped.push({
        [keys[0]]: [],
      });
    });

    if (graphDataGrouped.length <= 1) {
      setSecondError(
        "La cadena seleccionada no está representada en el segmento seleccionado."
      );
    } else {
      setSecondError(null);
    }
    if (filters.leverType === 0) {
      // TOTALES de cada palanca

      graphData.values2.forEach((item: any, index: number) => {
        let key = Object.keys(item)[0];
        datasetLabels.push(key);
        // Gets values of each GROUP (mercado sin mi, compañia, etc.)
        let collection = item[key];
        //console.log("Collection: ", collection);

        let collectionKeys = Object.keys(collection[0]); // Gets keys to palancas (Totales, Momento, Canal, etc.)
        //console.log("Collection keys: ", collectionKeys);
        datasetLabels = [...collectionKeys];

        // Map collections and build subseries
        let subseries: any = [];

        collectionKeys.forEach((key: any) => {
          if (key !== "Totales") {
            let newSubserie = {
              name: "",
              data: [],
              color: "",
            };

            newSubserie.name = key;
            newSubserie.data = collection
              .map((item: any) => item[key].Total || item?.Totales)
              .reverse()
              .flat();

            /*
          newSubserie.name = key;
          newSubserie.data = collection
            .map((item: any) => item[key])
            .reverse()
            .flat();
          newSubserie.color = "#ffffff";
					*/
            //console.log("NEW SUBSERIE: ", newSubserie);
            subseries.push(newSubserie);
          }
        });

        //console.log("SUBSERIESSSS: ", subseries);

        let currentDataSet: any = [];
        subseries.forEach(
          (
            serie: {
              name: string;
              data: number[];
              color: string;
              labels: string[];
            },
            index: number
          ) => {
            let color: string = serie.color;
            color = getItemHexColor("comparator", index);

            //let color = serie.color || rndColor;
            //console.log("SERE: ", serie, graphData);

            currentDataSet.push({
              type: "bar",
              label: serie.name,
              data: serie.data,
              stack: true,
              borderColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              backgroundColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              // hidden: legend[index]?.hidden || false,
              hidden: statusRef?.current[index]?.hidden || false,
            });

            //console.warn("SETTING ITEM: ", item);
          }
        );

        let data4: any = {
          labels: graphData.labels,
          datasets: currentDataSet,
        };
        if (index === 0) {
          setDataMercado(data4);
        } else {
          setDataYo(data4);
        }

        setDataTest(graphDataGrouped);
      });
    } else {
      // Por palanca seleccionada

      let subLever = leverTypeKeys[filters.leverType || 0];

      graphData.values2.forEach((item: any, index: number) => {
        //console.log("ITEM: ", item);
        let key = Object.keys(item)[0];
        datasetLabels.push(key);
        // Gets values of each GROUP (mercado sin mi, compañia, etc.)
        let collection = item[key];

        let collectionKeys = Object.keys(collection[0][subLever]); // Gets keys to palancas (Totales, Momento, Canal, etc.)

        collectionKeys = collectionKeys.filter((item: any) => item !== "Total");
        datasetLabels = [...collectionKeys];

        // Map collections and build subseries
        let subseries: any = [];

        collectionKeys.forEach((key: any, index: any) => {
          if (key !== "Totales" && key !== "Concesiones" && key !== "Otros") {
            let newSubserie = {
              name: "",
              data: [],
              color: "",
            };

            //console.log("KEY: ", key, index, collection[index][subLever][key]);

            if (filters.leverType === 3) {
              newSubserie.name = leverKeys.Region[key];
            } else {
              newSubserie.name = key;
            }
            newSubserie.data = collection
              .map((item: any) => item[subLever][key])
              .reverse()
              .flat();

            //console.log("NEW SUBSERIE: ", newSubserie);

            /*
          newSubserie.name = key;
          newSubserie.data = collection
            .map((item: any) => item[key])
            .reverse()
            .flat();
          newSubserie.color = "#ffffff";
					*/
            //console.log("NEW SUBSERIE: ", newSubserie);
            // If sublever === 3 rename keys to leverKeys.Region

            if (filters.lever === 0) {
              subseries.push(newSubserie);
            } else {
              let subkeys: any =
                Object.values(leverKeys)[filters.leverType - 1];

              if (
                subkeys[Object.keys(subkeys)[filters.lever]] ===
                newSubserie.name
              ) {
                subseries.push(newSubserie);
              }
            }
          }
        });
        // console.log("SUBSERIESSSS: ", subseries);

        let currentDataSet: any = [];

        subseries.forEach(
          (
            serie: {
              name: string;
              data: number[];
              color: string;
              labels: string[];
            },
            index: number
          ) => {
            let color: string = serie.color;
            color = getItemHexColor("comparator", index);

            //let color = serie.color || rndColor;
            //console.log("SERE: ", serie, graphData);

            currentDataSet.push({
              type: "bar",
              label: serie.name,
              data: serie.data,
              stack: true,
              borderColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              backgroundColor:
                hoveredIndex !== index && hoveredIndex !== -1
                  ? "rgba(255,255,255,0.10"
                  : color,
              // hidden: legend[index]?.hidden || false,
              hidden: statusRef?.current[index]?.hidden || false,
            });

            //console.warn("SETTING ITEM: ", item);
          }
        );

        let data4: any = {
          labels: graphData.labels,
          datasets: currentDataSet,
        };
        if (index === 0) {
          setDataMercado(data4);
        } else {
          setDataYo(data4);
        }

        setDataTest(graphDataGrouped);
      });
    }

    //console.warn("DATA SETS 2: ", graphDataGrouped);

    /* END OF DEVELOPMENT */

    //setDataTest(data3);
  }, [graphData, hoveredIndex, legend]);

  const [firstTitle, setFirstTitle] = useState("Mercado sin mí");
  useEffect(() => {
    if (filters.segment === 0) {
      setFirstTitle("Mercado sin mí");
    } else if (filters.segmentType === 0 && filters.segment === 1) {
      setFirstTitle("QSR sin mí");
    } else if (filters.segmentType === 0 && filters.segment === 2) {
      setFirstTitle("Casual sin mí");
    } else {
      setFirstTitle("Mercado sin mí");
    }
  }, [filters.segment, filters.segmentType]);

  return (
    <div className="chart-container">
      {dataMercado && (
        <div className="chart-wrapper">
          {updateExportData && <h1>{firstTitle}</h1>}
          <Chart
            type="bar"
            data={
              isCompatible &&
              !loading &&
              !secondError &&
              !(filters.entorno === Entorno.Comparable && filters.leverType > 0)
                ? dataMercado
                : emptyData
            }
            options={options}
          />
          {isCompatible && !loading && filters.segment === 123 && (
            <GraphNotCompatible>
              Seleccione un segmento diferente a Total
            </GraphNotCompatible>
          )}
          {isCompatible && !loading && secondError && (
            <GraphNotCompatible>{secondError}</GraphNotCompatible>
          )}
          {!isCompatible && !loading && (
            <GraphNotCompatible>
              {secondError ||
                `La configuración de filtros seleccionada no es compatible con este
              gráfico.`}
            </GraphNotCompatible>
          )}
          {filters.leverType === 0 && !loading && !secondError && (
            <GraphNotCompatible>
              {secondError ||
                `Seleccione la variable a observar en el panel de filtros.`}
            </GraphNotCompatible>
          )}
          {filters.entorno === Entorno.Comparable &&
            filters.leverType > 0 &&
            !loading && (
              <GraphNotCompatible>
                Esta gráfica no soporta el entorno comparable para la variable
                seleccionada.
              </GraphNotCompatible>
            )}
        </div>
      )}
      {dataYo && isMaximized && (
        <div className="chart-wrapper">
          <br />
          {updateExportData && <h1>Mi posición</h1>}
          <Chart
            type="bar"
            data={
              isCompatible &&
              !loading &&
              !secondError &&
              !(filters.entorno === Entorno.Comparable && filters.leverType > 0)
                ? dataYo
                : emptyData
            }
            options={options}
          />
          {isCompatible && !loading && secondError && (
            <GraphNotCompatible>{secondError}</GraphNotCompatible>
          )}
          {!isCompatible && !loading && (
            <GraphNotCompatible>
              {secondError ||
                `La configuración de filtros seleccionada no es compatible con este
              gráfico.`}
            </GraphNotCompatible>
          )}
          {filters.leverType === 0 && !loading && !secondError && (
            <GraphNotCompatible>
              {secondError ||
                `Seleccione la variable a observar en el panel de filtros.`}
            </GraphNotCompatible>
          )}
          {filters.entorno === Entorno.Comparable &&
            filters.leverType > 0 &&
            !loading && (
              <GraphNotCompatible>
                Esta gráfica no soporta el entorno comparable para la variable
                seleccionada.
              </GraphNotCompatible>
            )}
        </div>
      )}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
          <p>Obteniendo datos...</p>
        </div>
      )}
    </div>
  );
};

const getExportData = (
  data: any,
  datalabels: any[],
  labels: any,
  isLeap: boolean,
  currentFilters: any,
  leverTypeKeys: any,
  leverKeys: any
) => {
  let exportData: any = [];
  let arrayDeCosas: any = [];
  arrayDeCosas.push("Totales");

  let test: any = Object.values(data[0]);

  // Make an array of all "Descripcion" values in test[0]
  let test2: any = test[0].map((item: any) => item?.Descripcion);

  if (isLeap === false && currentFilters.leverType === 0) {
    //arrayDeCosas.push("Comparables");
  }
  for (let i = 0; i < test2.length; i++) {
    let tempVals: any = [];
    ["Ventas", "Tickets"].forEach((med: string, m: number) => {
      arrayDeCosas.forEach((ent: string, e: number) => {
        if (currentFilters.leverType === 0) {
          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i].Item[med][ent])
            )
            .flat()
            .reverse();

          let payload: any = {};

          tempVal.map((itemTemp: any, index: any) => {
            Object.keys(itemTemp).map((key: any) => {
              if (!payload[key]) {
                payload[key] = [itemTemp[key]];
              } else {
                payload[key].push(itemTemp[key]);
              }
            });
          });

          Object.keys(payload).map((key: any) => {
            tempVals[med + key] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        } else {
          let palanca = leverTypeKeys[currentFilters.leverType];
          let subPalanca: any = Object.values(leverKeys[palanca])[
            currentFilters.lever
          ];

          //console.log("HAY PALANCA: ", m, palanca, subPalanca);

          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i].Item[med][ent][palanca])
            )
            .flat()
            .reverse();

          let payload: any = {};

          tempVal.map((itemTemp: any, index: any) => {
            Object.keys(itemTemp).map((key: any) => {
              if (!payload[key]) {
                payload[key] = [itemTemp[key]];
              } else {
                payload[key].push(itemTemp[key]);
              }
            });
          });

          Object.keys(payload).map((key: any) => {
            tempVals[med + key] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        }
      });
    });
    exportData.push({ [test2[i]]: tempVals });
  }
  // Remove from exportData the ones whose key is not in datalabels
  let exportDataFiltered = exportData.filter((item: any) => {
    let key = Object.keys(item)[0];
    return datalabels.includes(key);
  });

  console.log("exportData: ", exportData, exportDataFiltered);
  return exportDataFiltered;
};

// Conceptos:
// Resultado de llamada telefónica 28-10-2022
/*
Mercado sin mí: ventas del mercado en cada variable / total del mercado sin mi cadena.

Mi posición: ventas de mi cadena en cada variable / total de ventas de mi cadena. */

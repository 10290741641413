import { useState, useEffect, useRef, useContext } from "react";
//import Line from "react-apexcharts";
//import { ResponsiveLine } from "@nivo/line";

import { Chart } from "react-chartjs-2";
import { Chart as Chart2 } from "chart.js";
import "chart.js/auto";
import { gql, useLazyQuery } from "@apollo/client";
import { Entorno } from "../../../utils/constants";
import {
  getItemHexColor,
  mergeKeyData,
  getMax,
  getMin,
  getSteps,
} from "../../../utils/functions";
import Chainz from "../../../dictionaries/Chainz.json";
import { Spinner, SpinnerSize } from "@fluentui/react";
import AppContext from "../../../AppContext";
import { GraphNotCompatible } from "../../../components/GraphNotCompatible";

import { getMedidaSQL } from "../../../utils/functions";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart2.register(ChartDataLabels);

//import './style.scss'

export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IBarPropsData = {
  datalabels: string[];
  labels: string[];
  values: number[][];
  colors: string[];
  annotation: IAnnotation[];
  series: any;
  horizontal?: boolean;
  min?: number;
  max?: number;
  stepSize?: number;
};
export type IBarProps = {
  data: IBarPropsData;
  hue: number;
  annotation: string;
  percentages?: boolean;
};

export const ComparadorTendencia = ({
  variables,
  hue,
  annotation,
  percentages,
  legend,
  updateLegend,
  updateExportData,
  showLegend,
}: any) => {
  let statusRef = useRef<any>([]);
  const graphRef = useRef<any>(null);
  let appContext = useContext(AppContext);
  const { semanas, filters, user } = useContext(AppContext);

  let currentFilters = useRef<any>(null);

  const medida = getMedidaSQL(filters.medidas);
  let entorno =
    filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

  let leverTypeKeys: any = [
    "Totales",
    "Canal",
    "Momento",
    "Region",
    "Ubicacion",
  ];
  let leverKeys: any = {
    Canal: ["Total", "Local", "TakeAway", "Delivery"],
    Momento: [
      "Total",
      "Mañanas",
      "Tardes",
      "Comidas (L-J)",
      "Cenas (L-J)",
      "Comidas (V-D)",
      "Cenas (V-D)",
    ],
    Region: ["Total", "Z1", "Z2", "Z3", "Z4", "Z5", "Z6"],
    Ubicacion: ["Total", "Calle", "CC", "Resto"],
  };

  const GET_GRAPH = gql`
    query (
      $yr1: Int!
      $wk1: Int!
      $yr2: Int!
      $wk2: Int!
      $var: Int
      $segment: String
      $segmentType: Int
      $misdatos: Int
    ) {
      graph(
        Graph: "Mi tendencia comparador"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: {
          Variables: $var
          Segmento: $segment
          SegmentType: $segmentType
          misdatos: $misdatos
        }
      )
    }
  `;

  const [graphData, setGraphData] = useState<any>(null);
  const [errorMsg, setErrorMsg] = useState<string>(
    `Se ha producido un error recuperando los datos`
  );

  const [isCompatible, setCompatible] = useState<boolean>(true);

  const [getData, { loading, error, data }] = useLazyQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (
      !currentFilters?.current ||
      (currentFilters?.current &&
        (filters.segmentType !== currentFilters?.current.segmentType ||
          filters.segment !== currentFilters?.current.segment))
    ) {
      getData({
        variables: {
          yr1: semanas[0].year,
          wk1: semanas[0].week,
          yr2: semanas[1].year,
          wk2: semanas[1].week,
          var: 1,
          segment:
            filters.segmentType === 0
              ? filters?.segmentos[filters?.segment]
              : filters?.especialidades[filters?.segment],
          segmentType: filters.segmentType,
          misdatos: filters?.misdatos,
        },
      });
    }
    currentFilters.current = { ...filters };
  }, []);

  useEffect(() => {
    getData({
      variables: {
        yr1: semanas[0].year,
        wk1: semanas[0].week,
        yr2: semanas[1].year,
        wk2: semanas[1].week,
        var: 1,
        segment:
          filters.segmentType === 0
            ? filters?.segmentos[filters?.segment]
            : filters?.especialidades[filters?.segment],
        segmentType: filters.segmentType,
        misdatos: filters?.misdatos,
      },
    });
  }, [semanas, filters?.misdatos, filters?.segment, filters?.segmentType]);

  useEffect(() => {
    currentFilters.current = { ...filters };
  }, [filters]);

  let labels: string[] = [],
    datalabels: string[] = [],
    dataName: string[] = [],
    values: number[][] = [],
    series: any = [];
  //let min = 0, max = 1

  useEffect(() => {
    if (data?.graph) {
      if (data?.graph?.Error) {
        setCompatible(false);
        setErrorMsg(data?.graph?.Error);
        return;
      }

      setCompatible(true);

      const medida = getMedidaSQL(filters.medidas);
      let entorno =
        filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

      const myData = data?.graph;

      console.log("myData: ", data);

      let fetchedData: any = data?.graph;

      if (!fetchedData) throw new Error(errorMsg);
      if (fetchedData?.Error) throw new Error(`${data.Error}`);

      let labels = fetchedData?.map((d: any) => Object.keys(d)[0]);
      console.log("LABELS: ", fetchedData, semanas);
      if (labels.length < 3)
        throw new Error(
          `No hay datos suficientes para mostrar el periodo indicado LOL.`
        );

      let datalabels = fetchedData[0][labels[0]].map(
        (d: { Descripcion: string }) => d.Descripcion
      );

      let dataName = fetchedData[0][labels[0]].map(
        (d: { Name: string }) => d.Name
      );

      let values: any = [];
      let series: any = [];

      // Filter Segment / Full market
      let segmentsArray: any = [];
      if (filters.segment === 0) {
        //
        segmentsArray = datalabels.filter(
          (item: string) => item?.substring(0, 4) === "Merc"
        );
      } else if (filters.segmentType === 0) {
        if (filters.segmentos[filters.segment]) {
          segmentsArray = datalabels.filter(
            (item: string) =>
              item?.substring(0, 3) ===
              filters.segmentos[filters.segment].substring(0, 3)
          );
        } else {
          segmentsArray = datalabels.filter(
            (item: string) =>
              item?.substring(0, 3) === filters.segmentos[1].substring(0, 3)
          );
        }
      } else {
        segmentsArray = datalabels.filter(
          (item: string) =>
            item?.substring(0, 3) ===
            filters.especialidades[filters.segment].substring(0, 3)
        );
      }

      // Filter my data
      let myDataArray: any = [];

      //! TO-DO: We need proper user.admin check in the frontend.
      if (appContext.user === "com_999999") {
        /*
        if (filters.misdatos === 0) {
          //const result = words.filter(word => word.length > 6);
          myDataArray = datalabels.filter(
            (item: string) => item?.toLowerCase() === "mi compañía"
          );
        } else {
          let cadenas = datalabels.filter(
            (item: string) => item?.substring(0, 4) === "cad_"
          );
          myDataArray = cadenas.filter(
            (item: string) =>
              item?.substring(0, 6) ===
              cadenas[filters.misdatos - 1].substring(0, 6)
          );
        }
				*/

        let cadenas = datalabels.filter(
          (item: string) => item?.substring(0, 4) === "cad_"
        );
        myDataArray = cadenas.filter(
          (item: string) => item === cadenas[filters.misdatos]
        );
      } else {
        let cadenas = datalabels.filter(
          (item: string) => item?.substring(0, 4) === "cad_"
        );

        myDataArray = cadenas.filter(
          (item: string) =>
            item?.substring(0, 6) === cadenas[filters.misdatos].substring(0, 6)
        );
      }

      let filteredLabels: any = [];
      segmentsArray.map((item: any) => filteredLabels.push(item));
      myDataArray.map((item: any) => filteredLabels.push(item));

      for (let i = 0; i < filteredLabels.length; i++) {
        let valueSub: any = [];
        fetchedData.map((dat: any): void => {
          let tempArray: any = [...Object.values(dat)];
          let index = tempArray[0].findIndex((item2: any) => {
            return (
              item2["Descripcion"].substring(0, 7) ===
              filteredLabels[i].substring(0, 7)
            );
          });

          let arrayOfItems = tempArray[0][index];
          if (filters.leverType === 0) {
            valueSub.push(arrayOfItems?.Item[medida][entorno].Totales);
          } else {
            let leverKey = leverTypeKeys[filters.leverType || 0];
            let leverSubKey = leverKeys[leverKey][filters.lever || 0];

            valueSub.push(
              arrayOfItems?.Item[medida][entorno][leverKey][leverSubKey]
            );
          }
        });
        values.push(valueSub.flat().reverse());
      }
      labels.reverse();

      values.forEach((val: any, i: any) => {
        // Find index in Chainz with key matching dataName[i]
        const index = Object.keys(Chainz).findIndex(
          (item: any) => item === dataName[i]
        );
        let color: any = "#ffffff";
        if (index !== -1) {
          //@ts-ignore
          color = `${Chainz[dataName[i]].color}`;
        }

        // get array from Totales in val
        let valArray = val.map((item: any) => item);

        series.push({
          name:
            filteredLabels[i].indexOf("cad_") > -1
              ? `${filteredLabels[i]}`
              : `${filteredLabels[i]}`,
          color: color,
          data: valArray,
        });
      });

      const graphData = {
        datalabels: filteredLabels,
        labels: labels,
        values: values,
        series: series,
      };

      if (updateExportData) {
        currentFilters.current = { ...filters };

        let exportData = getExportData(
          myData,
          filteredLabels,
          labels,
          semanas[0].year === 2019,
          currentFilters.current,
          leverTypeKeys,
          leverKeys
        );

        updateExportData(exportData);
      }

      setGraphData(graphData);
    }
  }, [data, filters, errorMsg]);

  let exportData: any[] = [];

  useEffect(() => {
    //@ts-ignore -- Limitación de apexcharts?
    if (graphRef.current && graphRef.current.props.series.length > 5) {
      data.series.forEach(
        (serie: { name: string; data: number[] }, i: number) => {
          if (i > 5) graphRef?.current?.chart.hideSeries(serie.name);
        }
      );
    }
  }, [data]);

  const newLegendClickHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem?.datasetIndex;
    const ci = legend.chart;

    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
    } else {
      ci.show(index);
      legendItem.hidden = false;
    }
    ci.update();

    // UpdateStatus
    let status = statusRef.current;
    if (status) {
      status[index].hidden = !status[index].hidden;
      //console.warn("STATUS: ", updateLegend);
      // updateLegend(status);
    }
  };

  const newLegendHoverHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const index = legendItem?.datasetIndex;
    const ci = legend.chart;
    setHoveredIndex(index);
    ci.update();
  };

  const newLegendLeaveHandler = function (
    e: any,
    legendItem: any,
    legend: any
  ) {
    const ci = legend.chart;
    setHoveredIndex(-1);
    ci.update();
  };

  const options = {
    responsive: true,
    tension: 0.4,
    animation: {
      duration: 400,
    },
    interaction: {
      intersect: false,
      mode: "index" as any,
    },
    plugins: {
      datalabels: {
        color: showLegend ? "white" : "transparent",
      },
      legend: {
        reverse: true,
        position: "bottom" as const,
        onClick: newLegendClickHandler,
        onHover: newLegendHoverHandler,
        onLeave: newLegendLeaveHandler,
        labels: {
          color: "rgb(255, 255, 255, 0.9)",
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Mi tendencia",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          drawTicks: true,
          tickColor: "#26292A",
          color: "#26292A",
        },
        ticks: {
          callback: function (value: any) {
            return value.toFixed(0) + "%"; // convert it to percentage
          },
        },
      },
    },
  };

  let [dataTest, setDataTest] = useState<any>({
    labels: [],
    datasets: [],
  });
  let [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  useEffect(() => {
    if (
      !graphData ||
      graphData?.labels?.length === 0 ||
      graphData?.series?.length === 0
    )
      return;
    let datasets: any = [];
    let segmentsCount: number = 0;
    let chainsCount: number = 0;

    graphData.series.forEach(
      (
        serie: { name: string; data: number[]; color: string },
        index: number
      ) => {
        let color: string = serie.color;
        if (serie.name?.toLowerCase().indexOf("mercado") > -1) {
          color = getItemHexColor("market");
        } else if (serie.name?.toLowerCase().indexOf("total") > -1) {
          color = getItemHexColor("total");
        } else if (serie.name?.toLowerCase().indexOf("compañía") > -1) {
          color = getItemHexColor("company");
        } else if (serie.name?.toLowerCase().indexOf("cad_") > -1) {
          color = getItemHexColor("chain", chainsCount);
          chainsCount += 1;
        } else {
          color = getItemHexColor("segment", segmentsCount);
          segmentsCount += 1;
        }

        //let color = serie.color || rndColor;

        datasets.push({
          type:
            serie.name.substring(0, 4) !== "cad_" &&
            serie.name.substring(0, 4).toLowerCase() !== "mi c"
              ? "bar"
              : "line",
          order:
            serie.name.substring(0, 4) !== "cad_" &&
            serie.name.substring(0, 4).toLowerCase() !== "mi c"
              ? "1"
              : "0",
          label: serie.name,
          data: serie.data,
          borderColor:
            hoveredIndex !== index && hoveredIndex !== -1
              ? "rgba(255,255,255,0.10"
              : color,
          backgroundColor:
            hoveredIndex !== index && hoveredIndex !== -1
              ? "rgba(255,255,255,0.10"
              : color,
          //hidden: legend[index]?.hidden || false,
          hidden: statusRef?.current[index]?.hidden || false,
        });
      }
    );
    let data3: any = {
      labels: graphData.labels,
      datasets: datasets,
    };

    if (statusRef.current.length === 0) {
      statusRef.current = datasets;
    }
    setDataTest(data3);
  }, [graphData, hoveredIndex, legend]);

  let emptyData = {
    labels: [],
    datasets: [],
  };

  return (
    <div className="chart-container">
      {/*dataTest && <Line data={dataTest} options={options} />*/}
      <div className="chart-wrapper">
        <Chart
          type="bar"
          data={
            !loading &&
            dataTest &&
            !(filters.entorno === Entorno.Comparable && filters.leverType > 0)
              ? dataTest
              : emptyData
          }
          options={options}
        />
        {!isCompatible && !loading && (
          <GraphNotCompatible>{errorMsg}</GraphNotCompatible>
        )}
      </div>
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
          <p>Obteniendo datos...</p>
        </div>
      )}
      {filters.entorno === Entorno.Comparable &&
        filters.leverType > 0 &&
        !loading && (
          <GraphNotCompatible>
            Esta gráfica no soporta el entorno comparable para la variable
            seleccionada.
          </GraphNotCompatible>
        )}
    </div>
  );
};

const getExportData = (
  data: any,
  datalabels: any[],
  labels: any,
  isLeap: boolean,
  currentFilters: any,
  leverTypeKeys: any,
  leverKeys: any
) => {
  let exportData: any = [];
  let arrayDeCosas: any = [];
  arrayDeCosas.push("Totales");

  let test: any = Object.values(data[0]);

  // Make an array of all "Descripcion" values in test[0]
  let test2: any = test[0]?.map((item: any) => item?.Descripcion);

  if (isLeap === false && currentFilters.leverType === 0) {
    //arrayDeCosas.push("Comparables");
  }
  for (let i = 0; i < test2.length; i++) {
    let tempVals: any = [];
    ["Ventas", "Tickets", "TicketMedio"].forEach((med: string, m: number) => {
      arrayDeCosas.forEach((ent: string, e: number) => {
        if (currentFilters.leverType === 0) {
          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i]?.Item[med][ent])
            )
            .flat()
            .reverse();

          let payload: any = {};
          tempVal.map((itemTemp: any, index: any) => {
            if (itemTemp) {
              Object.keys(itemTemp)?.map((key: any) => {
                if (!payload[key]) {
                  payload[key] = [itemTemp[key]];
                } else {
                  payload[key].push(itemTemp[key]);
                }
              });
            }
          });

          Object.keys(payload).map((key: any) => {
            tempVals[med + key] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        } else {
          let palanca = leverTypeKeys[currentFilters.leverType];
          let subPalanca: any = Object.values(leverKeys[palanca])[
            currentFilters.lever
          ];

          //console.log("HAY PALANCA: ", m, palanca, subPalanca);

          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i]?.Item[med][ent][palanca])
            )
            .flat()
            .reverse();

          let payload: any = {};

          tempVal.map((itemTemp: any, index: any) => {
            Object.keys(itemTemp).map((key: any) => {
              if (!payload[key]) {
                payload[key] = [itemTemp[key]];
              } else {
                payload[key].push(itemTemp[key]);
              }
            });
          });

          let Regiones: any = {
            Total: "Total",
            Z0: "Total",
            Z1: "Madrid",
            Z2: "Barcelona",
            Z3: "Norte",
            Z4: "Centro",
            Z5: "Noreste",
            Z6: "Sur",
          };

          Object.keys(payload).map((key: any) => {
            let label: any = key;
            if (currentFilters.leverType === 3) {
              label = Regiones[`${key}`];
            } else {
              label = key;
            }
            tempVals[med + label] = {
              ...mergeKeyData(labels, payload[key]),
            };
          });

          //tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        }
      });
    });

    exportData.push({ [test2[i]]: tempVals });
  }
  // Remove from exportData the ones whose key is not in datalabels
  let exportDataFiltered = exportData.filter((item: any) => {
    let key = Object.keys(item)[0];
    return datalabels.includes(key);
  });

  console.log("exportData: ", exportData, exportDataFiltered);
  return exportDataFiltered;
};

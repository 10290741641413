import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
//import { Chart, Bar } from 'react-chartjs-2';
import { customTooltip } from "./customTooltip";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./style.scss";

Chart.register(annotationPlugin);

const fontFamily =
  '"Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial';

type IAnnotationType =
  | "mercado"
  | "especialidad"
  | "segmento"
  | "other"
  | "total"
  | "desglose1"
  | "desglose2";
export type IAnnotation = {
  label: string;
  type: IAnnotationType;
  value: number;
};
export type IBarProps = {
  labels: string[];
  values: number[][];
  datalabels?: string[];
  annotation: IAnnotation[];
  highlights?: number[];
  stacked?: boolean;
  colors: string[];
  horizontal?: boolean;
  responsive?: boolean;
  stepSize?: number;
  percentages?: boolean;
  annotationPos?: "start" | "end";
  min?: number;
  max?: number;
};
const annotationColors = {
  especialidad: "#dd8114aa",
  segmento: "#0094f2aa",
  mercado: "#0F9D58aa",
  other: "#DB4437aa",
  total: "#154850aa",
  desglose1: "#153855aa",
  desglose2: "#5B7B15aa",
};
const highlightColors = [
  "#99b15b",
  "#5a8cc9",
  "#7b2ee4",
  "#ff3dc6",
  "#237e14",
  "#ffbf46",
  "#99673d",
];

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState<undefined | number>(undefined);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowWidth;
}

const BarGraph = ({
  datalabels = [],
  labels,
  values,
  annotation,
  highlights,
  stacked,
  colors,
  horizontal,
  stepSize,
  percentages,
  responsive,
  annotationPos = "start",
  min,
  max,
}: IBarProps) => {
  const steps = stepSize !== undefined ? stepSize : 0.1;

  const hasLegend = datalabels.length !== 0;

  const windowWidth = useWindowWidth();

  const flipped = horizontal
    ? horizontal
    : responsive
    ? !windowWidth || windowWidth < 2600
    : false;

  const getLabelPositions = (
    annotations: { label: string; type: string; value: number }[],
    annotationPos: "start" | "end"
  ) => {
    let positions: number[] = [];
    let max = Math.max(...annotations.map((d) => d.value));
    let min = Math.min(...annotations.map((d) => d.value));
    let block = (Math.abs(max) + Math.abs(min)) / 2;

    let pos = annotationPos === "start" ? 1 : -1;
    pos = flipped ? pos * -1 : pos;

    annotations = annotations.sort((a, b) => a.value - b.value);

    annotations.forEach((annotation, i) => {
      if (
        annotations[i - 1] &&
        annotation.value + block >= annotations[i - 1].value
      ) {
        let typePad =
          annotations[i].type === annotations[i - 1].type ? 0 : 20 * pos;
        positions.push(
          positions[i - 1] +
            annotations[i - 1].label.length * 8 * pos +
            45 * pos +
            typePad
        );
      } else {
        positions.push(0);
      }
    });
    return positions;
  };

  const generateAnnotations = () => {
    let annotations: any = {};

    let labelPositions = getLabelPositions(annotation, annotationPos);

    annotation.forEach((annotation, i) => {
      annotations[`line${i + 1}`] = {
        type: "line",
        yMin: flipped ? undefined : annotation.value,
        yMax: flipped ? undefined : annotation.value,
        xMin: flipped ? annotation.value : undefined,
        xMax: flipped ? annotation.value : undefined,
        drawTime: "afterDatasetsDraw",
        borderWidth: 4,
        borderDash: [4],
        borderColor: annotationColors[annotation.type],
        label: {
          backgroundColor: annotationColors[annotation.type],
          content: `${annotation.label}: ${annotation.value}%`,
          cornerRadius: 2,
          xPadding: 8,
          xAdjust: flipped ? 0 : labelPositions[i], //-70,
          yAdjust: flipped ? labelPositions[i] * -0.3 : 7,
          position: flipped ? "start" : "end",
          drawTime: "afterDatasetsDraw",
          font: {
            size: 10,
            weight: 400,
            fontFamily: fontFamily,
          },
          enabled: false,
          display: true,
        },
      };
    });

    return annotations;
  };

  const [anno, setAnno] = useState(generateAnnotations());

  const handleResize = () => {
    setAnno(generateAnnotations());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getHighlightedColors = (
    colors: string[],
    highlights: number[],
    alpha: string = ""
  ) => {
    return highlights.map((pos) =>
      pos === -1 ? colors[0] : highlightColors[pos] + alpha
    );
  };

  const getDataset = (
    datalabels: string[],
    values: number[][],
    colors: string[],
    highlights: number[] | undefined
  ) => {
    let result: any = [];
    values.forEach((value: number[], i: number) => {
      result.push({
        label: datalabels[i],
        data: value,
        backgroundColor: highlights
          ? getHighlightedColors(colors, highlights, "C0")
          : [colors[i] + "C0"],
        hoverBackgroundColor: highlights
          ? getHighlightedColors(colors, highlights)
          : [colors[i]],
      });
    });

    return result;
  };

  function handleHover(_evt: any, item: any, legend: any) {
    graphRef.current.style.cursor = "pointer";

    for (let i = 0; i < legend.legendItems.length; i++) {
      if (i !== item.datasetIndex) {
        if (
          legend?.chart?.datasets &&
          legend?.chart?.datasets[i]?.backgroundColor
        ) {
          legend?.chart?.datasets[i]?.backgroundColor.forEach(
            (color: string, index: number, colors: string[]) => {
              colors[index] =
                index === item.index || color.length === 9
                  ? color
                  : color + "4D";
            }
          );
        }
      }
    }
    legend.chart.update();
  }

  function handleLeave(_evt: any, item: any, legend: any) {
    graphRef.current.style.cursor = "default";

    for (let i = 0; i < legend.legendItems.length; i++) {
      if (i !== item.datasetIndex) {
        if (
          legend?.chart?.datasets &&
          legend?.chart?.datasets[i]?.backgroundColor
        ) {
          legend.chart.datasets[i].backgroundColor.forEach(
            (color: string, index: number, colors: string[]) => {
              colors[index] = color.length === 9 ? color.slice(0, -2) : color;
            }
          );
        }
      }
    }
    legend.chart.update();
  }

  //function handleClick(_evt:any, item:any, legend:any) {
  //	legend.chart.update();
  //}

  const labelsAxis = {
    stacked: !!stacked,
    grid: {
      display: true,
      color: "transparent",
      borderColor: "#777",
      drawTicks: true,
      //tickBorderDash: [8],
      tickColor: "#777",
      //tickLength: 8
    },
    ticks: {
      beginAtZero: true,
      color: "#eee",
      alignment: "center",
      //maxTicksLimit: 30,
      font: {
        size: 11,
        weight: 400,
        family: fontFamily,
      },
    },
  };

  const ticksAxis = {
    stacked: !!stacked,
    max: max,
    min: min,
    grid: {
      color: "#3a3a3a",
      display: true,
      borderColor: "transparent",
      //borderColor: '#777'
    },
    ticks: {
      beginAtZero: true,
      //stepSize: steps,
      padding: 5,
      color: "#eee",
      font: {
        size: 11,
        weight: 400,
        family: fontFamily,
      },
      callback: function (value: any, i: number, ticks: any) {
        // Si no tiene centesimales, dibujar:
        return ticks.length > 8
          ? (value * 10) % 2 === 0 || i === 0 || i === ticks.length - 1
            ? value + "%"
            : undefined
          : value + "%";
      },
    },
  };

  // mobile: 0.75, desktop: 1.15

  const options: any = {
    max: 100,
    responsive: true,
    barThickness: "flex",
    borderWidth: 2,
    aspectRatio:
      typeof windowWidth === "number" && windowWidth > 450 ? 1.25 : 0.75,
    indexAxis: flipped || false ? "y" : "x",
    plugins: {
      legend: {
        display: hasLegend,
        position: "bottom",
        onHover: handleHover,
        onLeave: handleLeave,
        //onClick: handleClick,

        labels: {
          usePointStyle: true,
          boxWidth: 8,
          color: "white",
          backgroundColor: "red",
          padding: 12,
          font: {
            family: fontFamily,
            size: 12,
            weight: 400,
          },
        },
      },
      tooltip: {
        enabled: false,
        intersect: false,
        mode: flipped ? "y" : "x",
        position: "nearest",
        padding: 12,
        external: customTooltip,
      },
      annotation: {
        annotations: {
          line0: {
            type: "line",
            yMin: flipped ? undefined : 0,
            yMax: flipped ? undefined : 0,
            xMin: flipped ? 0 : undefined,
            xMax: flipped ? 0 : undefined,
            drawTime: "beforeDatasetsDraw",
            borderWidth: 1,
            borderColor: "#777",
            responsive: true,
          },
          ...generateAnnotations(),
        },
      },
      datalabels: {
        color: "white",
        align: "center",
        clamp: true,
        textStrokeColor: (val: any) => {
          let strokeColor =
            typeof highlights === "object" && highlights[val.dataIndex] > -1
              ? highlightColors[highlights[val.dataIndex]]
              : colors[val.datasetIndex];
          return adjustColor(strokeColor, -64) + "A0" ?? "#00000077";
        },
        textStrokeWidth: 2,
        font: {
          size: 13,
          weight: 400,
          family: fontFamily,
        },
        formatter: (val: any) => {
          return `${val}${percentages ? "%" : ""}`;
        },
      },
    },
    scales: {
      x: flipped ? ticksAxis : labelsAxis,
      y: flipped ? labelsAxis : ticksAxis,
    },
  };

  function adjustColor(color: string, amount: number) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  }

  const graphRef = useRef(document.createElement("div"));

  console.log("labels: ", labels);
  console.log("dataset: ", getDataset(datalabels, values, colors, highlights));

  return (
    <div className="chartjs-container">
      <div ref={graphRef}>
        <Bar
          //type={flipped?"flippedBar":"bar"}
          data={{
            labels: labels,
            datasets: getDataset(datalabels, values, colors, highlights),
          }}
          options={options}
          plugins={[ChartDataLabels]}
        />
      </div>
    </div>
  );
};

export default BarGraph;

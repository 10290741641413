import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { PrimaryButton, Checkbox } from "@fluentui/react";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";

const Parametros = () => {
  const [maintenance, setMaintenance] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);

  const MUTATION_CODE = gql`
    mutation ($maintenance: Boolean) {
      setInitialData(input: { Maintenance: $maintenance }) {
        Completed
        Error
      }
    }
  `;

  const [setRow] = useMutation(MUTATION_CODE, {
    onCompleted: (fetchedData) => {
      console.log("mutation: ", fetchedData);
      getData();
    },
  });

  const PARAM_QUERY = gql`
    query ($showAll: Boolean) {
      getInitialData(showAll: $showAll)
    }
  `;

  const [getData, { loading, error, data }] = useLazyQuery(PARAM_QUERY, {
    fetchPolicy: "no-cache",
    variables: { showAll: true },
    onCompleted: (fetchedData) => {
      console.log("fetchedData", fetchedData);
      setMaintenance(fetchedData.getInitialData?.maintenance);
      /*
      fetchedData.getCadena.forEach((cadena: ICadena) => {

      });*/
    },
  });

  useEffect(() => {
    getData();
  }, []);

  /*
  useEffect(() => {
    console.log(
      "qry.data?.getInitialData?.maintenance",
      qry?.data?.getInitialData?.maintenance
    );
    setMaintenance(!!qry.data?.getInitialData?.maintenance);
  }, [qry.data]);
*/

  function _onChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {
    if (!(ev?.target instanceof HTMLInputElement)) return;
    console.log("sending", isChecked);
    setRow({ variables: { maintenance: isChecked } }).then(() => {
      //qry.refetch();
      //setShowRefresh(true);
    });
  }

  return (
    <>
      <Title title={"Mantenimiento"} />

      <div className={`table-container small`}>
        <Checkbox
          label={`El modo mantenimiento está ${
            maintenance ? "activado" : "desactivado"
          }. Para ${
            maintenance ? "desactivarlo" : "activarlo"
          } haga click en el checkbox.`}
          className={"visibilityCheckbox"}
          checked={maintenance}
          onChange={_onChange}
        />
      </div>
    </>
  );
};

export default Parametros;

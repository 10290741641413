import React, { useContext, useMemo } from "react";
import Title from "../../../components/Title";
import LineGraph from "../../../components/LineGraph";
import { gql, useQuery } from "@apollo/client";
import AppContext, { IFilters } from "../../../AppContext";
import { Entorno } from "../../../utils/constants";
import {
  getMax,
  getMedidaSQL,
  getMin,
  getSteps,
  mergeKeyData,
} from "../../../utils/functions";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";

export const title = `Indicadores de tendencia`;
export const filters: IFilters = {
  limitedPeriodo: true,
  showMedidas: true,
  hasTicketMedio: true,
  isDesglose: false,
  showEntorno: true,
  showSegmentos: false,
  showMyData: false,
};

const Graph = () => {
  const { semanas, filters, user } = useContext(AppContext);
  const medida = getMedidaSQL(filters.medidas);
  let entorno =
    filters.entorno === Entorno.Comparable ? "Comparables" : "Totales";

  const GET_GRAPH = gql`
    query ($yr1: Int!, $wk1: Int!, $yr2: Int!, $wk2: Int!, $var: Int) {
      graph(
        Graph: "Indicadores de tendencia"
        WeekRange: {
          Start: { Year: $yr1, Week: $wk1 }
          End: { Year: $yr2, Week: $wk2 }
        }
        Filters: { Variables: $var }
      )
    }
  `;

  const qry = useQuery(GET_GRAPH, {
    fetchPolicy: "no-cache",
    variables: {
      yr1: semanas[0].year,
      wk1: semanas[0].week,
      yr2: semanas[1].year,
      wk2: semanas[1].week,
      var: 1, //filters.variables
    },
  });

  let errorMsg = `Se ha producido un error recuperando los datos`;
  let loading = qry.loading || qry.data === undefined;
  let error = !semanas || !!qry.error;

  let labels: string[] = [],
    datalabels: string[] = [],
    values: number[][] = [],
    series: any = [];
  //let min = 0, max = 1

  let exportData: any[] = [];
  if (!loading && !error && qry.data.graph) {
    try {
      const data = qry?.data?.graph;
      console.log("INDICADORES DE TENDENCIA", data);
      if (!data) throw new Error(errorMsg);
      if (data?.Error) throw new Error(`${data.Error}`);

      labels = data.map((d: any) => Object.keys(d)[0]);
      if (labels.length < 3)
        throw new Error(
          `No hay datos suficientes para mostrar el periodo indicado.`
        );

      datalabels = data[0][labels[0]].map(
        (d: { Descripcion: string }) => d.Descripcion
      );

      for (let i = 0; i < datalabels.length; i++) {
        values.push(
          data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i].Item[medida][entorno])
            )
            .flat()
            .reverse()
        );
      }

      labels.reverse();

      //max = roundGraph(Math.max(...values.reduce((a,b)=>[...a,...b])))
      //min = roundGraph(Math.min(...values.reduce((a,b)=>[...a,...b])))

      values.forEach((val, i) => {
        series.push({
          name: datalabels[i],
          data: val,
        });
      });

      exportData = getExportData(
        data,
        datalabels,
        labels,
        semanas[0].year === 2019
      );
    } catch (err: any) {
      error = true;
      errorMsg = err.message ?? errorMsg;
    }
  }

  //max=max<0?0:max

  //const ticks = useMemo(() => getTicks(min,max),[min,max]);
  const max: number | undefined = useMemo(() => getMax(values), [values]);
  const min: number | undefined = useMemo(() => getMin(values), [values]);
  const stepSize = useMemo(() => getSteps(min, max), [min, max]);

  const graphData = {
    datalabels: datalabels,
    labels: labels,
    values: values,
    colors: [
      "#007070",
      "#A30041",
      "#85b709",
      "#888888",
      "#363aaf",
      "#9a2d79",
      "#4d947a",
      "#7EA3CF",
      "#DC9E9D",
    ],
    annotation: [],
    series: series,
    min: min,
    max: max,
    stepSize: stepSize,
  };

  return (
    <>
      <Title
        title={title}
        error={!!error}
        files={{ data: exportData, semanas, user, title, leyenda: "Periodo" }}
      />
      {error && <MessageBar isMultiline={true}>{errorMsg}</MessageBar>}
      {loading && (
        <div className="loading-graph">
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      {!error && (
        <LineGraph
          data={graphData}
          hue={320}
          annotation={"#00ff00"}
          percentages
        />
      )}
    </>
  );
};

export default Graph;

const getExportData = (
  data: any,
  datalabels: any[],
  labels: any,
  isLeap: boolean
) => {
  let exportData: any = [];

  for (let i = 0; i < datalabels.length; i++) {
    let tempVals: any = [];
    ["Ventas", "Tickets"].forEach((med: string, m: number) => {
      (isLeap ? ["Totales"] : ["Totales", "Comparables"]).forEach(
        (ent: string, e: number) => {
          let tempVal = data
            .map((dat: any) =>
              Object.values(dat).map((d: any) => d[i].Item[med][ent])
            )
            .flat()
            .reverse();
          tempVals[med + ent] = { ...mergeKeyData(labels, tempVal) };
        }
      );
    });
    exportData.push({ [datalabels[i]]: tempVals });
  }

  return exportData;
};
